@charset 'utf-8';

@import 'global/colours';
@import 'global/variables';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-switch;
@include foundation-table;
// Basic components
// @include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
// @include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
// @include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
//@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

@import '../../../node_modules/flickity/css/flickity';

$lg-path-images: '/assets/i';
@import '../../../node_modules/lightgallery.js/src/sass/lightgallery';


@import 'global/structure';
@import 'global/typography';

@import 'components/header';
@import 'components/nav';
@import 'components/footer';

@import 'components/events';
@import 'components/news';
@import 'components/card';
@import 'components/gallery';
@import 'components/sliders';

@import 'components/gdpr-cookie-notice';

table {
    width: auto;
}

.scrollTop {
    position:fixed;
    z-index:99995;
    bottom:30px;
    right:21px;
    overflow:hidden;
    opacity:1;
    visibility:visible;
    transition:opacity .3s ease,visibility .3s ease,transform .4s ease;
    a {
        display:block;
        width:40px;
        height:40px;
        border:1px solid #cacaca;
        box-sizing:border-box;
        background-color:#fff;
        text-decoration:none;

        &:before {
            border-color:transparent transparent $lt-blue transparent;
            position:absolute;
            content:"";
            width:0;
            left:3px;
            margin-left:13px;
            margin-top:-10px;
            border-style:solid;
            border-width:5px 5px 6px;
            height:1px;
            top:50%
        }
    }
}

table {
    tr {
        th, td {
            > p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        th {
            text-align: left;
            vertical-align: top;
        }
    }
}

.news-img {
    margin-bottom: 2rem;
}

.tabs-content {
    border-top: 1px solid $light-gray;
}