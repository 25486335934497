.pt {
    padding-top: $padding-small;
    @include breakpoint(medium) {
        padding-top: $padding-medium;
    }
    @include breakpoint(large up) {
        padding-top: $padding-large;
    }    
}
.pb {
    padding-bottom: $padding-small;
    @include breakpoint(medium) {
        padding-bottom: $padding-medium;
    }
    @include breakpoint(large up) {
        padding-bottom: $padding-large;
    }   
}

.mt {
    margin-top: $padding-small;
    @include breakpoint(medium) {
        margin-top: $padding-medium;
    }
    @include breakpoint(large up) {
        margin-top: $padding-large;
    }    
}
.mb {
    margin-bottom: $padding-small;
    @include breakpoint(medium) {
        margin-bottom: $padding-medium;
    }
    @include breakpoint(large up) {
        margin-bottom: $padding-large;
    }   
}

.nohero {
    margin-top: $padding-small;
    @include breakpoint(medium) {
        margin-top: $padding-medium;
    }
    @include breakpoint(large up) {
        margin-top: $padding-large;
    }      
}


.bg-blue {
    background-color: $lt-blue;
    color: white;
    a {
        color: #FFFFFF;
        font-weight: $global-weight-bold;
        text-decoration-line: underline;
        text-decoration-style: dotted;
        &:hover {
            color: scale-color(#FFFFFF, $lightness: -14%);
        }   
    }
    h1,h2,h3,h4,
    .h1,.h2,.h3,.h4 {
        color: white;
        > a {
            text-decoration: none;
            font-weight: initial;
        }
    }
}
.bg-brown {
    background-color: $lt-brown;
    color: white;
    a {
        color: #FFFFFF;
        font-weight: $global-weight-bold;
        text-decoration-line: underline;
        text-decoration-style: dotted;
        &:hover {
            color: scale-color(#FFFFFF, $lightness: -14%);
        }   
    }
    h1,h2,h3,h4,
    .h1,.h2,.h3,.h4 {
        color: white;
        > a {
            text-decoration: none;
            font-weight: initial;
        }
    }
}

.bg-darkgrey {
    background-color: $darkgrey;
    color: white;
    a {
        color: white;
        font-weight: $global-weight-bold;
        text-decoration-line: underline;
        text-decoration-style: dotted;
        &:hover {
            color: scale-color(white, $lightness: -14%);
        }        
    }
    h1,h2,h3,h4,
    .h1,.h2,.h3,.h4 {
        color: white;
        > a {
            text-decoration: none;
            font-weight: inherit;
        }        
    }
}

.bg-lightgrey {
    background-color: $lightgrey;
    color: black;
    a {
        color: black;
        font-weight: $global-weight-bold;
        text-decoration-line: underline;
        text-decoration-style: dotted;
        &:hover {
            color: $lt-blue;
        }
    }
    h1,h2,h3,h4,
    .h1,.h2,.h3,.h4 {
        color: black;
        > a {
            text-decoration: none;
            font-weight: inherit;
        }        
    }    
}