$oca-text: $lightgrey;
$oca-active: $lt-blue;

.oca-events-calendar {
  // reset
  // .pagination {
  //   border: none;
  //   padding: 0;
  //   margin: 0;
  // } 
  // button {
  //   padding: 0;
  //   appearance: none;
  //   border: 0;
  //   border-radius: 0;
  //   background: 0 0;
  // }  

  margin-bottom: 50px;
  position: relative;
  z-index: 1;


  .pagination {
    .current {
      background-color: #{$oca-active};
    }
  }
  a {
    color: #{$oca-active};
  }

  #oca-events-nav {
    &.is-stuck {
      background-color: white;
      padding: 10px 0;
    }
    &.sticky.is-at-top.is-stuck {
      // margin-top: 2em !important;
      @include breakpoint(medium up) {
        margin-top: 5.8em !important;
      }
      @include breakpoint(large up) {
        margin-top: 3.5em !important;
      }
    }
    text-align: center;
    border-bottom: 1px solid $lightgrey;
    padding-bottom: 10px;
    }
  

  #oca-event-years-wrapper {
    width: 500px;
    padding: 0 20px;
    margin: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    height: 30px;
    line-height: 24px;
    display: none;
    @include breakpoint(medium up) {
      display: inline-block;
      .paddles {
        .paddle {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 30px;
          color: $lightgrey;
        }
        .left-paddle {
          left: -7px;
        }
        .right-paddle {
          right: -7px;
        }
      }
    }
  }

  #oca-event-years {
    display: none;
    max-width: 460px;
    border-bottom: 1px solid grey;
    //   padding: 0 20px;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }

    > li {
      height: 30px;
    }

  }
  #oca-event-years-mobile {
    width: auto;
  }
  @include breakpoint(medium up) {
    #oca-event-years-mobile {
      display: none;
    }
    #oca-event-years {
      display: inline-block;
    }
  }

  .select-event-year {
    line-height: inherit;
    cursor: pointer;
  }

  #oca-event-search {
    width: 80%;
    display: inline-block;
    .input-group-field {
      height: 2.2rem;
    }
    .button {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      background-color: #{$oca-active};
    }
    select {
      font-size: 0.85rem;
    }

    @include breakpoint(medium up) {
      width: auto;
      .input-group-field {
        flex-grow: 0;
        transition: min-width 0.3s ease-in-out;
        min-width: 5rem;
        &:focus {
          min-width: 12rem;
        }
      }
    }
  }

  #oca-eventsdata {
    margin-top: 2rem;
    margin-bottom: 2rem;

    #oca-ongoingevents {
      background-color: #eee;

      .event {
        &:last-child {
          border-bottom: 2px dashed $lightgrey;
        }
      }
    }

    #oca-searchresults {
      .event-date--year {
        display: block;
      }
    }

    // Event row
    .event {
      border-bottom: 1px solid $lightgrey;
      &:last-child {
        border-bottom: none;
      }
      @include breakpoint(medium up) {
        border-bottom: none;
      }

      // Set defaults for event colour/style if there's no parent class
      .eventtype-label {
        @include label;
        background: $oca-text;
        color: white;
        margin-right: 5px;
      }
      .eventMiniURL {
        color: $oca-text;
        font-weight: $global-weight-bold;
        &.button {
          color: white;
        }
      }
    .event-more {
        font-style: italic;
        color: #{$oca-active};
        &.button {
            color: white;
        }
    }

      // &.training {
      //     .eventtype-label {
      //         background: $ft-blue;
      //         color: white;
      //     }
      //     .eventMiniURL,
      //     .event-more {
      //         color: $ft-blue;
      //     }
      // }
      // &.racing {
      //     .eventtype-label {
      //         background: $ft-red;
      //         color: white;
      //     }
      //     .eventMiniURL,
      //     .event-more {
      //         color: $ft-red;
      //     }
      // }

      .date-range {
        display: flex;
        align-items: center;
      }

      .date-to {
        display: inline-block;
        margin: 0 0.2rem;
        @include breakpoint(medium up) {
          margin: 0 0.2rem;
        }
        text-align: center;
      }

      .event-date {
        display: inline-block;
        text-align: center;
        min-width: 2.3rem;

        &--day {
          font-size: 0.9rem;
          border-width: 1px;
          border-color: grey;
          border-bottom-style: solid;
          border-left-style: solid;
          border-right-style: solid;
          border-top-style: none;
          line-height: 1.2;
          padding: 2px;
        }

        &--month {
          font-size: 0.8rem;
          line-height: 1.1;
          font-weight: $global-weight-bold;
          text-transform: uppercase;
          background-color: $oca-text;
          color: white;
          padding: 2px;
          border-color: $oca-text;
          border-width: 1px;
          border-bottom-style: none;
          border-left-style: solid;
          border-right-style: solid;
          border-top-style: solid;
          // border: 1px solid #9c001b;
        }
        &--year {
          display: none;
          font-size: 0.6rem;
        }
      }

      .event-venue {
        display: block;
        font-size: 0.9rem;
      }

      .event-more {
        margin-bottom: 0;
        margin-top: 5px;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-left: 0.75em;
        padding-right: 0.75em;
        font-size: 0.875;
        font-style: italic;
        background-color: #{$oca-active};
      }

      .resultdocs {
        @include menu-base;
        @include menu-direction("vertical");
        @include menu-icons;

        @include breakpoint(small only) {
          @include menu-align(right);
        }

        li.resultdoc {
          font-size: 0.9rem;
          a {
            padding: 0 1rem 0.7rem 0;
          }
          .svg-inline--fa {
            margin-right: 0.25rem;
          }
        }
      }
    }
  }

  .goToToday.button {
    cursor: pointer;
    color: #{$oca-active};
    border-color: #{$oca-active};
  }

  #oca-today {
    clear: both;
    max-width: 75rem;
    height: 0;
    margin: 1.25rem auto;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid #cacaca;
    border-left: 0;
    position: relative;
    &::after {
      content: "TODAY";
      text-align: right;
      font-size: small;
      position: absolute;
      right: 0;
    }
  }

  #oca-months {
    display: none;
    @include breakpoint(medium up) {
      display: block;
    }
  }
}
