$padding-large: 50px;
$padding-medium: 50px;
$padding-small: 30px;

$block-padding-small: 2rem;
$block-padding-medium: 3rem;

$header-weight-bold: 700;
$header-weight-normal: 500;


@mixin block-padding {
    padding: $block-padding-small;
    @include breakpoint(medium up) {
        padding: $block-padding-medium;
    }
}
