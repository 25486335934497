#header {
    margin-top: 1rem;
    margin-bottom: 1rem;

    .social {
        font-size: 1.75rem;
        line-height: 1;
        @include breakpoint(medium up) {
            font-size: 2rem;
        }
        a {
            color: $darkgrey;
            &:hover {
                color: $lt-brown;
            }
        }
    }
}
.hero {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 200px;
    @include breakpoint(medium up){
        height: 350px;
    }
    @include breakpoint(large up) {
        height: 400px;
    }

    &--deep {
        height: 250px;
        @include breakpoint(medium up){
            height: 400px;
        }
        @include breakpoint(large up) {
            height: 550px;
        }
    }

    .hero-title--container {
        height: 100%;
        .hero-title--title {
            font-size: 2rem;
            
            color: white;
            max-width: 100%;
            display: block;
            margin-bottom: 50px;

            @include breakpoint(medium up) {
                font-size: 3rem;
                max-width: 66%;
                margin-bottom: 70px;
            }
            @include breakpoint(large up) {
                font-size: 4rem;
                max-width: 50%;
                margin-bottom: 100px;
            }
        }
    }
}