#load-more-holder {
  text-align: center;
}

.news-card {
  .card {
    background-color: $darkgrey;
    transition: background-color 300ms ease;
    color: black;

    &:hover {
      background-color: $lt-blue;
    }

    .card-section {
      a {
        color: white;
        text-decoration: none;
      }
    }

    .news-card--date {
        font-size: 0.8rem;
        font-style: italic;
        @include breakpoint(medium up) {
          text-align: right;
        }
        color: white;
    }
    .news-card--title {
      font-size: 1rem;
      @include breakpoint(medium up) {
        font-size: 1.1rem;
      }
        font-weight: $global-weight-bold;
        margin-bottom: 5px;
    }

    .read-more {
      display: none;
      @include breakpoint(medium up) {
        display: block;
      }
    }
  }

}


