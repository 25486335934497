.no-js {
    @include breakpoint(small only) {
      .top-bar {
        display: none;
      }
    }
  
    @include breakpoint(medium) {
      .title-bar {
        display: none;
      }
    }
}
#nav {
    .title-bar-logo {
        height: 37px;
        opacity: 0;
        transition: opacity .25s ease-in-out;
    }
    &.is-stuck {
        .title-bar-logo {
            opacity: 1;
        }
    }

    background-color: $lt-blue;
    .menu {
        a {
            color: white;
            white-space: nowrap;
            @include breakpoint(large up) {
                margin-right: 1rem;
            }            
            @include breakpoint(xlarge up) {
                margin-right: 2rem;
            }
        }
        &.dropdown {
            &.is-dropdown-submenu {
                margin-top: 0.5rem;
            }
            > li.is-submenu-item {
                border-bottom: none;
                > a {
                    background-color: $lt-blue;
                    padding: 1rem 1.2rem;
                    &:hover {
                        text-decoration: underline;
                        // background-color: white;
                        // color: $ft-blue;
                    }
                }
            }
        }
    }

    .menu.medium-horizontal.dropdown > li > a {
        text-align: left;
    }

}