a.read-more {
    text-decoration-line: none;
}

.pdflink {
    background-image: url("/assets/i/icons/page_white_acrobat.png");
    background-color: transparent;
    background-position: left center;
    background-repeat: no-repeat;
    padding-left: 22px !important;
}
.xlslink {
    background-image: url("/assets/i/icons/page_excel.png");
    background-color: transparent;
    background-position: left center;
    background-repeat: no-repeat;
    padding-left: 22px !important;
}
.newWindow {
    background-image: url("/assets/i/icons/ext_link.png");
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 15px;
}

@include breakpoint(medium up) {
    .twocols {
        column-count: 2;
    }
}

.button {
    text-transform: uppercase;
}