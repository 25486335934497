#home-news-slider {
    .slide {
        // display: flex;
        // align-items: flex-end;
        position: relative;
        min-height: 100%;
        padding: 30px;
        @include breakpoint(medium up) {
            padding: 50px;
            height: 300px;
        }
        @include breakpoint(large up) {
            padding: 70px;
            height: 400px;
        }        
        max-width: $global-width - rem-calc(30);
        width: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center right;


        
        .news-content {
            &--heading {
                
            }
            &--text {

            }
        }
    }
}
